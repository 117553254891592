import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import remarkBreaks from "remark-breaks";
import SyntaxHighlighterWrapper from "./forwardRefs";
import { preprocessChildren } from "../utils/replaceMarkDown";

const UseMarkdown = ({ children, ClassName }: { children: string; ClassName: string }) => {
	const processedChildren =
		typeof children === "string" ? preprocessChildren(children) : children;

	// Check if the input contains code blocks
	const hasCodeBlocks = /```/.test(children ?? "");

	const formatCodeBlock = (code: string) => {
		// Remove extra newlines and escape characters
		return code?.replace(/\\n/g, "\n")?.replace(/\\"/, "\n")?.trim();
	};

	return hasCodeBlocks ? (
		<Markdown
			children={formatCodeBlock(children)}
			className={ClassName}
			remarkPlugins={[remarkGfm, remarkBreaks]}
			components={{
				code({ children, className, ...rest }) {
					const match = /language-(\w+)/.exec(className || "");
					return match ? (
						<SyntaxHighlighterWrapper {...rest} language={match[1]}>
							{formatCodeBlock(children as string)}
						</SyntaxHighlighterWrapper>
					) : (
						<code
							{...rest}
							className={className}
							style={{
								backgroundColor: "#f5f5f5",
								padding: "2px 4px",
								borderRadius: "4px",
								fontFamily: "monospace",
								fontSize: "13px",
								color: "#555",
								fontStyle: "italic",
								whiteSpace: "pre-wrap",
								wordBreak: "break-word",
							}}
						>
							{children}
						</code>
					);
				},
			}}
		/>
	) : (
		<div
			className={ClassName}
			dangerouslySetInnerHTML={{
				__html: processedChildren ?? "",
			}}
		/>
	);
};

export default UseMarkdown;
